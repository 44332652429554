<template>
  <div style="padding: 10px">
    <span class="menu_title">从{{this.dateFrom}}到{{this.dateTo}}，通过白嫖奖励+氪金，最终资源如下：</span>
    <span class="menu_title">资源不足优先购买黑市周的，其次每日黑市，爬塔小鱼干啥的一定要冷静！</span>
    <el-table
            v-loading="loading"
            :data="tableDataResult"
            border="bold"
            stripe
            style="width: 100%">
      <el-table-column align="center"
                       prop="remark"
                       label="说明">
      </el-table-column>
      <el-table-column align="center"
                       prop="recruit"
                       label="招募令">
      </el-table-column>
      <el-table-column align="center"
                       prop="box"
                       label="宝箱">
      </el-table-column>
      <el-table-column align="center"
                       prop="fishGod"
                       label="金鱼竿">
      </el-table-column>
      <el-table-column align="center"
                       prop="gold"
                       label="金砖">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

  import request from "@/utils/request";

  export default {
    name: 'Dnf',
    components: {},
    data() {
      return {
        tableDataResult: [],
        dateFrom:'',
        dateTo:'',
        labelPosition: 'right',
        loading: true,
      }
    },
    created() {
      this.load();
    },
    methods: {
      load() {
        let userId = this.$route.query.userId;
        if(userId === undefined || userId === '') {
          let userStr = sessionStorage.getItem("user") || "{}"
          let user = JSON.parse(userStr)
          userId = user.id
        }
        this.loading = true
        request.get("/saltedFishInitData/compute", {
          params: {
            userId: userId
          }
        }).then(res => {
          if (res.code !== '0') {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          const data = res.data;
          this.dateFrom = data.dateFrom
          this.dateTo = data.dateTo
          this.tableDataResult = [{
            remark:'活动前一天资源',
            recruit: data.recruitCount,
            box: data.boxCount,
            fishGod: data.fishGodCount,
            gold: data.goldCount
          },{
            remark:'与目标差距（临时）',
            recruit: data.recruitLess,
            box: data.boxLess,
            fishGod: data.fishGodLess,
            gold: data.goldLess
          },{
            remark:'黑市周购买次数',
            recruit: data.recruitBMNum,
            box: data.boxScoreBMNum,
            fishGod: data.fishGodBMNum,
            gold: '——'
          },{
            remark:'黑市周购买资源消耗金砖',
            recruit: data.recruitBMGoldCount,
            box: data.boxScoreBMGoldCount,
            fishGod: data.fishGodBMGoldCount,
            gold: '——'
          },{
            remark:'黑市周购买完成后资源情况',
            recruit: data.recruitAfterBMNeedCount,
            box: data.boxScoreAfterBMNeedCount,
            fishGod: data.fishGodAfterBMNeedCount,
            gold: data.goldAfterBMNeedCount
          },{
            remark:'从X开始购买每日黑市资源（招募每日买20个，宝箱每日买140分，金鱼竿每日买10个）',
            recruit: data.recruitBMDayFrom,
            box: data.boxScoreBMDayFrom,
            fishGod: data.fishGodBMDayFrom,
            gold: '——'
          },{
            remark:'每日黑市购买消耗的金砖',
            recruit: data.recruitBMDayGoldCount,
            box: data.boxScoreBMDayGoldCount,
            fishGod: data.fishGodBMDayGoldCount,
            gold: '——'
          },{
            remark:'每日黑市购买完成资源情况',
            recruit: data.recruitAfterBMDayNeedCount,
            box: data.boxScoreAfterBMDayNeedCount,
            fishGod: data.fishGodAfterBMDayNeedCount,
            gold: data.goldAfterBMDayNeedCount
          }];
          this.loading = false
        })
      },
    }
  }
</script>
<style>
  .menu_title {
    color: #409EFF;
    font-weight: bold;
  }
</style>
