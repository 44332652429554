<template>
  <div style="padding: 10px">
    <el-row :gutter="10">
      <el-col :span="24">
        <el-card>
          <div id="myChart" :style="{width: '100%', height: '500px'}"></div>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Home",
  data() {
    return {}
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$root.echarts.init(document.getElementById('myChart'));
      let option = {
        title: {
          text: '每日资源情况',
          subtext: '',
          x: 'center'
        },
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            restore: {show: true},
            saveAsImage: {show: true}
          }
        },
        // legend: {
        //   orient: 'horizontal',
        //   x: 'left',
        //   y: 'top',
        //   data: ['收益']
        // },
        grid: {
          top: '20%',
          left: '3%',
          right: '10%',
          bottom: '5%',
          containLabel: true
        },
        tooltip: {  // tooltip 用于控制鼠标滑过或点击时的提示框（下一章展开讲）
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器配置项。
            type: 'none', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            axis: 'auto', // 指示器的坐标轴。
            snap: true, // 坐标轴指示器是否自动吸附到点上
          },
          showContent: true,
          formatter: function(arg) {
            return arg[0].value + '万'
          }
        },
        xAxis: {
          name: '日期',
          type: 'category',
          data: []
        },
        yAxis: {
          name: '资源',
          type: 'value',
          min:0, // 配置 Y 轴刻度最小值
          // max:4000,  // 配置 Y 轴刻度最大值
          // splitNumber:7,  // 配置 Y 轴数值间隔
        },

        series: [ // 多组折线图数据
          {
            name: '收益',
            data: [],
            type: 'line'
          },
        ],
        color: ['#99CC33']
      }
      myChart.setOption(option);
/*      let userStr = sessionStorage.getItem("user") || "{}"
      let user = JSON.parse(userStr)
      request.get("/dnf/echarts",{
        params: {
          userId: user.id,
        }
      }).then(res => {
        if (res.code === '0') {
          res.data.forEach(item => {
            option.xAxis.data.push(item.date)
            option.series[0].data.push(item.income)
            // option.series[0].data.push({name: item.date, value: item.income})
          })
          // 绘制图表
          myChart.setOption(option);
        }
      })*/

    }
  }
}
</script>

<style scoped>

</style>
